import React from 'react';

import './style.scss';

// If it's got an @ in the middle and stuff before and after, the odds are very good that it's a
// valid email address. See https://hackernoon.com/the-100-correct-way-to-validate-email-addresses-7c4818f24643
// for some "fun" examples.
const validEmailRegex = /.+@.+/;

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
    };
  }

  updateName = event => this.setState({ name: event.target.value });
  updateEmail = event => this.setState({ email: event.target.value });

  readyToSubmit = () => this.state.email.match(validEmailRegex)

  render() {
    return (
      <form
        action="https://idealabstraction.us20.list-manage.com/subscribe/post?u=5badb62d3fc19c5368073281c&amp;id=11e351bd7e"
        className="subscribe-form"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        novalidate
      >
        <label for="mce-NAME">Name:</label>
        <input
          type="text"
          name="NAME"
          id="mce-NAME"
          value={this.state.name}
          onChange={this.updateName}
        />
        <label for="mce-EMAIL">Email Address:</label>
        <input
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          value={this.state.email}
          onChange={this.updateEmail}
        />
        {/*
            Honeypot for automated signups.
            If anything is in this field (which humans can't see), Mailchimp rejects
          */}
        <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
          <input type="text" name="b_5badb62d3fc19c5368073281c_11e351bd7e" tabindex="-1" value="" />
        </div>
        <input
          className="button"
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          disabled={!this.readyToSubmit()}
        />
      </form >
    );
  }
}

export default SubscribeForm;
