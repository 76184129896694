import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import HeaderImage from 'components/HeaderImage';
import SubscribeForm from 'components/SubscribeForm';

const SubscribePage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Follow us!" />
      <HeaderImage image={header}>
        <h1>Follow Us</h1>
      </HeaderImage>

      <p>We put out educational material on the internet and teach classes. To get updates on our
        new content, join our mailing list!
      </p>

      <SubscribeForm />
    </Layout >
  );
};

export default SubscribePage;

export const query = graphql`
  query SubscribePageQuery {
          header: file(name: {eq: "neonbrand-551954-unsplash" }) {
          childImageSharp {
        fluid(maxWidth: 800, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    }
  `;
